@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,600;1,400;1,900&family=Open+Sans:ital,wght@0,300;0,600;1,400;1,800&display=swap);
.Loading_containerbody___aPk9 {
  display: flex;
  position: absolute;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgba(255,255,255,0.7);
}

